import {useStore} from "../store/useStore";


export default function ChooseLanguage({langContainer, toggleLangSelect}) {
    if(window.sq) console.log('render ChooseLanguage')
    const [lang, setLang] = useStore(state => [state.lang, state.setLang])
    const LangUrl = window.FACADE_CONFIGURATOR.languageUrls
    //console.log('LangUrl', LangUrl)

    const chooseLang = (lang) => {
        const langInteral =  lang.split('-').shift()
        // console.log ('langInteral', langInteral)

        if (window.isLocalhost)     setLang(langInteral)                    // local nur Sprache switchen
        else                        document.location.href = LangUrl[lang]  // Wechsel zu anderer Url
    }
    

    return (
        <div ref={langContainer} className="choose-language" onMouseLeave={() => toggleLangSelect(false)}>
				<table>
					<tbody><tr>
						<td>Deutschland, Österreich, Schweiz</td>
						<td>
                            {LangUrl['de-DE'] && <a onClick={() => chooseLang('de-DE')}>deutsch</a>}
                            {LangUrl['fr-DE'] && <> / <a onClick={() => chooseLang('fr-DE')}>francais</a></>}
                            {LangUrl['it-DE'] && <> / <a onClick={() => chooseLang('it-DE')}>italiano</a></>}
                            {LangUrl['en-DE'] && <> / <a onClick={() => chooseLang('en-DE')}>english</a></>}
						</td>
					</tr>
					{(LangUrl['nl-NL'] || LangUrl['en-NL']) && <tr>
						<td>Nederland</td>
						<td>
                            {LangUrl['nl-NL'] && <a onClick={() => chooseLang('nl-NL')}>nederlands</a>}
                            {LangUrl['en-NL'] && <> / <a onClick={() => chooseLang('en-NL')}>english</a></>}
						</td>
					</tr>}
					{(LangUrl['fr-FR'] || LangUrl['en-FR']) && <tr>
						<td>France</td>
						<td>
                            {LangUrl['fr-FR'] && <a onClick={() => chooseLang('fr-FR')}>francais</a>}
                            {LangUrl['en-FR'] && <> / <a onClick={() => chooseLang('en-FR')}>english</a></>}
						</td>
					</tr>}
					{(LangUrl['it-IT'] || LangUrl['en-IT']) && <tr>
						<td>Italia</td>
						<td>
                            {LangUrl['it-IT'] && <a onClick={() => chooseLang('it-IT')}>italiano</a>}
                            {LangUrl['en-IT'] && <> / <a onClick={() => chooseLang('en-IT')}>english</a></>}
						</td>
					</tr>}
					{(LangUrl['cs-CZ'] || LangUrl['en-CZ']) && <tr>
						<td>Česká republika</td>
						<td>
                        {LangUrl['cs-CZ'] && <a onClick={() => chooseLang('cs-CZ')}>cesky</a>}
                        {LangUrl['en-CZ'] && <> / <a onClick={() => chooseLang('en-CZ')}>english</a></>}
						</td>
					</tr>}
					{LangUrl['en-SE'] && <tr>
						<td>Nordics</td>
						<td>
							<a onClick={() => chooseLang('en-SE')}>english</a>
						</td>
					</tr>}
					{LangUrl['en-GB'] && <tr>
						<td>United Kingdom</td>
						<td>
							<a onClick={() => chooseLang('en-GB')}>english</a>
						</td>
					</tr>}
                    {LangUrl['en-AU'] && <tr>
						<td>Australia</td>
						<td>
							<a onClick={() => chooseLang('en-AU')}>english</a>
						</td>
					</tr>}
					{(LangUrl['zh-CN'] || LangUrl['en-CN']) && <tr>
						<td>中华人民共和国</td>
						<td>
                        {LangUrl['zh-CN'] && <a onClick={() => chooseLang('zh-CN')}>中国</a>}
                        {LangUrl['en-CN'] && <> / <a onClick={() => chooseLang('en-CN')}>english</a></>}
						</td>
					</tr>}
					<tr>
						<td>
							Alle anderen Länder
						</td>
						<td>
							<a onClick={() => chooseLang('en')}>english</a>
						</td>
					</tr>
				</tbody>
            </table>
		</div>
    )   
}



