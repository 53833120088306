import React, {Suspense} from 'react';
import {useRef, useState, useEffect } from 'react'
import {useStore} from "../store/useStore";
import t from '../Translation'
import Spinner from "./Spinner";
import Login from "./Login";



function SaveProject(props) {
    if (window.sq) console.log("render SaveProject")
    //console.log("render SaveProject")
    const sqCom = false

    const [saveProjectActive, setSaveProjectActive]         = useStore((state) => [state.saveProjectActive, state.setSaveProjectActive])
    const [top, bottom, config, lang]                       = useStore((state) => [state.top, state.bottom, state.config, state.lang])  
    const [setName]                                         = useStore((state) => [state.setName])    
    const [setId]                                           = useStore((state) => [state.setId]) 
    const [setImageSliderActive]                            = useStore((state) => [state.setImageSliderActive]) // compare ausblenden, wenn ein Porjekt geladen wird
    
    
    const [projects, setProjects]                           = useState([]) 
    const [listCounter, setListCounter]                     = useState(0)      
    const [setSideBarActive, setTrigger]                    = useStore((state) => [state.setSideBarActive, state.setTrigger])
    const [setTop, setBottom, setLang, setConfig]           = useStore((state) => [state.setTop, state.setBottom, state.setLang, state.setConfig])
    const decorObj   = window.FACADE_CONFIGURATOR.decors    
    let blockReSave = useRef()

    // Konvertierung der Altprojekte
    const convertObj = {
        balcony:        ["pattern1"],
        hotel:          ["pattern1","pattern2","pattern3"],
        house:          ["pattern1","pattern2","pattern3"],
        industry:       ["pattern1","pattern2"],
        kindergarden:   ["pattern1","pattern2"],
        residential:    ["pattern1"]
    }

    
    const clearInput = (e) => {
        e.target.previousSibling.value = ''
    }



    useEffect(() => {   
        if (window.PFL_AUTHENTICATED) { 
            if (saveProjectActive) {              
                // Modus Save: erst speichern dann Projekte anzeigen

                if (saveProjectActive == 'save' && !blockReSave.current) {
                    setProjects([])             // Fenster leeren
                    //console.log('props.canvas.current', props.canvas.current)
                    window.setTimeout(saveToServer, 400)              // aktuelles Projekt wegspeichern, im Hintergrund

                    if (sqCom) console.log('--- Save current Project')
                    //console.log('--- Save current Project')

                // Modus My Projects
                } else {                    
                    getProjectList()
                }      
            }
        }
        document.addEventListener('keydown', handleKey)
        return () => document.removeEventListener('keydown', handleKey)

    },[saveProjectActive, listCounter])



    const toggleEdit = (e, save) => {                                       // console.log('toggleEdit', e)
        const pn        = e.target.parentNode.parentNode                    // console.log('pn', pn)
        const pnHead   = pn.querySelector('h3')
        const pnEdit   = pn.querySelector('.projectname-edit')
        const input    = pn.querySelector('.projectname-edit input')
        //console.log('pnEdit', pnEdit)
        //console.log('input', input)
        if (!input.value) input.value = 'Project1'

        // Editbereich anzeigen
        if (pnEdit.classList.contains('hidden')) {
            input.value = pnHead.querySelector('span').innerText
            pnEdit.classList.remove('hidden'); pnHead.classList.add('hidden')

        // Editbereich ausblenden
        } else {
            pnEdit.classList.add('hidden'); 
            pnHead.querySelector('span').innerText = input.value; 
            pnHead.classList.remove('hidden')
            if(pn.id == config.id) setName(input.value) // wenn es das aktuelle Projekt ist, dann Name in Config ändern
        }
        if (save) {                                                         // id de Projectes auslesen
            //console.log('Update Projektname id', pn.id)
            saveToServer(input.value, pn.id, 'onlyName') //
        }
    }



    


    


    // diese Funktion kann man noch optimieren/aufsplitten. Aktuell wird immer ein blob erzeugt, auch wenn nur der Name geändert wird, evtl. too much...
    const saveToServer = (projectName='Project1', id='', onlyName=false) => {
        //console.log('---- saveToServer')
        //console.log('name, id, onlyName', projectName, id, onlyName)

        window.setSpinner(true)
        props.canvas.current.toBlob(
			(blob) => {
				
                let useScript, initial
                if (!config.id) {useScript = window.FACADE_API_CREATE_PROJECT; initial = true} // noch keine id, dann CREATE
                else            {useScript = window.FACADE_API_UPDATE_PROJECT}  // hat id, dann UPDATE
                const formData = new FormData()		
                //console.log('blob', blob)


                // nur Name updaten
                if (onlyName && id) {
                    formData.append('name', projectName)  
                    formData.append('id', id)
                    //console.log('--- Update only name', id, projectName)
                    useScript = window.FACADE_API_UPDATE_PROJECT

                // aktuelles Project sichern oder Updaten
                } else {                  		
                    if (config.id) formData.append('id', config.id) // id des aktuellen Porjektes mitschicken = update
                    formData.append('image', blob, 'facade.jpg')                
                    if (config.name) projectName = config.name      // Name
                    formData.append('name', projectName)                                
                    // Decore hinzufuegen, ohne Doppelte!
                    let tmpArr = []
                    top.decors.map((decor)    => {tmpArr.push(decor[0])})
                    bottom.decors.map((decor) => {tmpArr.push(decor[0])})
                    tmpArr = [...new Set(tmpArr)] // Doppelte entfernen
                    tmpArr.map((decor)        => {formData.append('decor_ids[]', decor)  })              
                    formData.append('configuration',  JSON.stringify({top: top, bottom:bottom, config: config, lang: lang}))
                    window.changes = 0
                    
                    
                    // tracking
                    if (window.gtag) {
                        (typeof window.gtag === "function" && window.gtag("event", "facadeconfigurator_save_project", { "facadeconfigurator_object": config.object, "facadeconfigurator_mounting": config.mounting }))
                    }

                    //console.log('---- Update/Save full project', config.id, projectName)

                }
                
                //if (sqCom) console.log('Sending POST request to backend:', window.baseUrl + useScript)
                //if (sqCom) console.log('saveToServer formData', formData)
                
                //console.log('Sending POST request to backend:', window.baseUrl + useScript)
                //console.log('saveToServer formData', formData)

               
                // fetch(window.baseUrl + window.FACADE_API_CREATE_PROJECT, {
				fetch(window.baseUrl + useScript, {
					method: 'POST',
					body: formData
				})
					.then((res) => {
                        //console.log('res', res); 
                        //console.log('res.status', res.status); 
                        // Prüfen noch authorized? (Fuer den Fall, dass man sich bei Pfleiderer abmeldet, die App nicht neu lädt...)
                        if (res.status == 401) {
                            //console.log('SaveProjects 401'); 
                            window.setSpinner()
                            // setSaveProjectActive(false)
                            window.PFL_AUTHENTICATED = false 
                            //setSaveProjectActive(true)
                        }
                        res.text()})
					.then((data) => {

						//console.log('---- Created or Updated Project id', data)
                        if (initial) {
                            setId(data)
                            window.changes = 0 // 
                        }
                        window.setSpinner()
                        getProjectList() // Server hat geantwortet, neuladen nicht nötig
					})
			},
			"image/jpeg",
			0.95
		)
    }

    const getProjectList = () => {
        if (sqCom) console.log('---- Listing Projects');
		// if (sqCom) console.log('Sending GET request to backend', window.baseUrl + window.FACADE_API_LIST_PROJECTS);
        window.setSpinner(true)
		fetch(window.baseUrl + window.FACADE_API_LIST_PROJECTS)
			.then((res) => {
                //console.log('res', res); 
                // Prüfen noch authorized? (Fuer den Fall, dass man sich bei Pfleiderer abmeldet, die App nicht neu lädt...)
                if (res.status == 401) {
                    console.log('401'); 
                    window.setSpinner()
                    // setSaveProjectActive(false)
                    window.PFL_AUTHENTICATED = false 
                    //setSaveProjectActive(true)
                }
                return res.json()
            })
			.then((data) => {
				//console.log('Fetched projects', data)
                setProjects(data)
                window.setSpinner()
			})
    }

    /*
    const getProjectDetails = () => {
        window.setSpinner(true)
        if (sqCom) console.log('---- Get Project');
		// console.log('Sending GET request to backend', window.baseUrl + window.FACADE_API_GET_PROJECT + '&tx_epim_facadegenerator[id]=xxxxxxx',);

		fetch(window.baseUrl + window.FACADE_API_GET_PROJECT + '&tx_epim_facadegenerator[id]=xxxxxxx', )
			.then((res) => {console.log(res); return res.json()})
			.then((data) => {
				console.log('Fetched projects', data)
                window.setSpinner()
			})
    }
    */ 
    const deleteProject = (id) => {
        if (sqCom) console.log('---- delete id', id);
        window.setSpinner(true)
        fetch(window.baseUrl + window.FACADE_API_DELETE_PROJECT + '&tx_epim_facadegenerator[id]=' + id).then((data) => {
            //console.log('Deleted project', data)
            setListCounter(listCounter + 1)    
            window.setSpinner()     
        }) 
        // aktuelles Projekt "neutralisieren", damit keine id mitgeschickt udd wieder neu gespeichert werden kann
        if (config.id == id) {
            setName('')
            setId('')  
            blockReSave.current = true
        }  
    }

    const deleteAll = () => {
        if (sqCom) console.log('---- deleteAll');
		// console.log('Sending GET request to backend');
        window.setSpinner(true)
        blockReSave.current = true
        
        /*
        let c = 0, l = projects.length // protokollieren
        // Durchlauf in Schleife
        projects.map((project) => {           
                console.log('deleteAll del.',  project.id) // alle löschen bis auf das aktuelle
                fetch(window.baseUrl + window.FACADE_API_DELETE_PROJECT + '&tx_epim_facadegenerator[id]=' + project.id).then((data) => {
                    console.log('Deleted project', data); 
                    c++
                    if (c==l) setListCounter(listCounter + 1)  // nur ausführen, wenn alle deletes success melden                
                })           
            }           
        )    
        */   

        fetch(window.baseUrl + window.FACADE_API_DELETE_ALL_PROJECTS).then((data) => {
            console.log('Delete all projects', data);           
            setListCounter(listCounter + 1) // Fenster aktualisieren           
        })    
        window.setSpinner()
        
        // aktuelles Projekt "neutralisieren", damit keine id mitgeschickt und wieder neu gespeichert werden kann
        setName('')
        setId('')         
    }


    const loadProject = (projectID) => {
        if (sqCom) console.log('---- loadProject');
		// if (sqCom) console.log('Sending GET request to backend', window.baseUrl + window.FACADE_API_GET_PROJECT + '&tx_epim_facadegenerator[id]=' + projectID);
        
        window.setSpinner(true)
		fetch(window.baseUrl + window.FACADE_API_GET_PROJECT + '&tx_epim_facadegenerator[id]=' + projectID)
			.then((res) => {return res.json()})
			.then((json) => {
				//console.log('Fetched projects', json)
                const data = JSON.parse(json.configuration)
                const config    = data.config
                const top       = data.top
                const bottom    = data.bottom
                const lang      = data.lang
                
                // altes Projekt konvertieren, das noch keine Patterns kennt
                if (config && !config.patterns) {
                    config.patterns = convertObj[config.object] // Patterns nach Object 
                    config.pattern  = 'pattern1' // Default
                }

                //console.log('top, bottom, config, lang', top, bottom, config, lang)
                window.setSpinner()
                setTop(top)
                if (config.conf2) setBottom(bottom) // Current config only if there is a bottom
                setConfig(config)
                setLang(lang)
                window.configurationTmpObj = {'top': [], 'bottom': []}
                window.masks = null
                setSideBarActive(false)
                setImageSliderActive(false) // Compare ausblenden
                setTrigger(['both', false])   
            }
        )
    }
   

    const handleKey = (e) => {
        //console.log(e.keyCode)
        if (e.keyCode == 13) {
            setSaveProjectActive(false) 
            blockReSave.current = false
        }
    }



    const deactivateParent = () => {
        setSaveProjectActive(false)
    }
    
    if (saveProjectActive) {
        if (!window.PFL_AUTHENTICATED) {
            return <Login active={true} deactivateParent={deactivateParent}/>
        }
        return (
            <div className={saveProjectActive ? 'save-project active' : 'save-project'} onClick={(e) => {if (window.chckClck(e)) setSaveProjectActive(false)}}>
                <Suspense fallback={<Spinner/>}>
                    <div className="inner dialog">
                        {/* <form> */}
                            <h2>{t[lang].myprojects}</h2>
                            <div className="scroller">   
                            {/* <button className="" onClick={() => saveToServer()}>saveToServer</button>
                            <button className="" onClick={() => getProjectList()}>getList</button>
                            <button className="" onClick={() => getProjectDetails()}>getDetails</button>
                            <button className="" onClick={() => deleteAll()}>deleteAll</button> */}

                                {projects && projects.map((project, index) => (
                                    <div className={'row  project'+index} key={index} projectid={project.id} id={project.id}>
                                        <h3 className="projectname"><span>{project.name}</span><button className="icon edit" onClick={toggleEdit}>&nbsp;</button></h3>
                                        <span className="projectname-edit hidden">
                                            <input type="text" defaultValue={project.name ? project.name : 'Project 1'}/>
                                            <button onClick={clearInput} className="clear-text"/>
                                            <button className="icon save" onClick={(e) => toggleEdit(e, true)}>{t[lang].projectsave}</button>
                                        </span>
                                        <br/>
                                        <img className="preview" width="237" draggable="false" src={window.baseUrl + project.thumbnail} />
                                        <div className="wrap">
                                            {project.decor_ids && project.decor_ids.map((decorID, dndx) => (

                                                <a target="_blank" href={window.baseUrl + decorObj[decorID].detail_link} className="decor" key={dndx}>                                
                                                    <img draggable="false" src={window.baseUrl + decorObj[decorID].image_src} />                                       
                                                    <h3>{decorID}</h3>
                                                    <p>{decorObj[decorID].subtitle}</p>
                                                </a>

                                            ))}
                                        </div>
                                        <br />
                                        <button className="icon delete" onClick={() => deleteProject(project.id)}>{t[lang].projectdelete}</button>
                                        {config.id == project.id && window.changes < 1 ? '' : <button className="icon load" onClick={() => loadProject(project.id)}>{t[lang].projectload}</button>}
                                    </div>
                                ))}


                            </div>  
                            <div className="footer"> 
                                <button className="icon delete" onClick={deleteAll}>{t[lang].deleteall}</button>
                                <button className="right submit" type="submit"  onClick={() => {setSaveProjectActive(false); blockReSave.current = false}}>{t[lang].close}</button>
                            </div>
                    </div>
                </Suspense>
            </div>
        )
    } else {
        return <div className={saveProjectActive ? 'save-project active' : 'save-project'}></div>
    } 
}

export default SaveProject
