import React, {useEffect} from 'react';
import {useStore} from "../store/useStore";
import t from '../Translation'



function HelpLayer() {
    if (window.sq) console.log("render Help")
    const [helpLayerActive, setHelpLayerActive] = useStore((state) => [state.helpLayerActive, state.setHelpLayerActive]) 
    const [config, lang]                       = useStore((state) => [state.config, state.lang]) 

    

    useEffect(() => {   
        document.addEventListener('keydown', handleKey)
        return () => document.removeEventListener('keydown', handleKey)
    },[])


    const handleKey = (e) => {
         if (e.keyCode == 13) {
            setHelpLayerActive(false) 
        }
    }

    if (helpLayerActive) {
        return (
            <div className={helpLayerActive ? 'help-layer active' : 'help-layer'} onClick={(e) => {if (window.chckClck(e)) setHelpLayerActive(false)}}>
                <div className="inner dialog">
                    <div className="scroller">
                        <div className="stretcher">
                        {/* <img className={'dummy-img help ' + lang}         src={'assets/help/helpMobilDummyImg-' + lang + '.gif'} /> 
                        <img className={'desktop-dummy-img help ' + lang} src={'assets/help/helpDesktopDummyImg.gif'} />  */}


                        <img className={'dummy-img help ' + lang}         src={'/assets/help/PFL_KO_FASSADE2223_Anleitungsgrafik_' + lang.toUpperCase() + '_LY01_Mobil.svg'} /> 
                        <img className={'desktop-dummy-img help ' + lang} src={'/assets/help/PFL_KO_FASSADE2223_Anleitungsgrafik_' + lang.toUpperCase() + '_LY01_Desktop.svg'} /> 


                        <div className="closer" onClick={() => setHelpLayerActive(false)}/>
                        <div className="startbutton" onClick={() => setHelpLayerActive(false)}/>
                        </div>  

                    </div>
                </div>
            </div>
        ) 
    }
}

export default HelpLayer
