import React, {useEffect, useState, useRef} from 'react'
import {useStore}       from "../store/useStore";
import SettingsPattern  from './SettingsPattern';
import SettingsDecor    from './SettingsDecor';
import SettingsMounting from './SettingsMounting';
import t from '../Translation'
import { CheckBoxOutlineBlankTwoTone } from '@mui/icons-material';




function FacadeSettingsBottom({active, backButton, tabsArr}) {
    if (window.sq) console.log("render FacadeSettingsBottom")

    const lang = useStore(state => state.lang)   
    const [tabs, setTabs]       = useState([0,0,1]) // Defaultwert, wird durch useEffect rrunterschschaltet auf [0,1,0] Dekore activ
    const [footerlink, setFooterlink] = useStore((state) => [state.footerlink, state.setFooterlink])  
    const [config]              = useStore((state) => [state.config])  
    const didMount = useRef(false);


    useEffect(() => {
        //console.log('useEffect Bottom')
		if      (tabs[1])    toggleTabs([1,0,0])
        else if (tabs[2])    toggleTabs([0,1,0])      
	}, [tabsArr])


    useEffect(() => {       
        if (active == 'bottom') {
            //console.log('useEffect Bottom Every')
            if (tabs[0])  backButton.current.style.display = 'none'
            else          backButton.current.style.display = 'inline-block' 
        }
	})


    const toggleTabs = (arr) => {
        setTabs(arr)
        if (arr[0])  backButton.current.style.display = 'none'
        else         backButton.current.style.display = 'inline-block'
	}


    return (
        <div className={active == 'bottom' ? 'settings bottom active' : 'settings bottom'}>
            <h2>{config.conf2 ? t[lang][config.conf2[2]] : ''}</h2>
            <ul className="tabs">
                <li className={tabs[0] ?  'active' : ''} onClick={() => {toggleTabs([1,0,0]); setFooterlink(1)}}>{t[lang].pattern}</li>
                <li className={tabs[1] ?  'active' : ''} onClick={() => {toggleTabs([0,1,0]); setFooterlink(0)}}>{t[lang].decor}</li>
                <li className={tabs[2] ?  'active' : ''} onClick={() => {toggleTabs([0,0,1]); setFooterlink(1)}}>{t[lang].mounting}</li>
            </ul>
            <br className="clear" />
            <SettingsPattern    active={tabs[0]} facade="bottom" />
            <SettingsDecor      active={tabs[1]} facade="bottom" />
            <SettingsMounting   active={tabs[2]} facade="bottom" /> 
        </div>       
    )
}

export default FacadeSettingsBottom
