import React from 'react';
import {useStore} from "../store/useStore";
import {useState, useEffect} from 'react';
import t from '../Translation'

let dArr = []
/*
dArr = [
    'f76006',
    'f76037',
    'f76115',
    'f76143',
    'f76146',
    'f76149',
    'r20027',
    'r20038',
    'r20095',
    'r20320',
    'r20351',
    'r34015',
    'r38000',
    'r50084',
    'r55004',
    'r55072',
    's60036',
    's63028',
    's64011',
    'u11102',
    'u12000',
    'u12044',
    'u12115',
    'u12188',
    'u12215',
    'u16051',
    'u16058',
    'u18004',
    'u18029',
    'u18074',
    'u18501',
    'u18505',
    'u19007',
    'u19015',
] 
*/
    

function ChooseDecor() {
    if(window.sq) console.log('render ChooseDecor')
    
    const [page, setPage] = useState(0)
    const lang = useStore(state => state.lang)
    
    const [selectDecorActive, setSelectDecorActive] = useStore((state) => [state.selectDecorActive, state.setSelectDecorActive])
    const [top, setTop, bottom, setBottom] = useStore((state) => [state.top, state.setTop, state.bottom, state.setBottom]) 
    const [setTrigger] = useStore((state) => [state.setTrigger])

    const decorObj      = window.FACADE_CONFIGURATOR.decors
    // console.log('decorObj', decorObj)
    for(const key in decorObj) {
        dArr.push(key)  
    }
    // console.log('dArr', dArr)
    const slicer        = 20
    const decorsKeys    = dArr                                      // Object.keys(decorObj)
    let pageRange       = page*slicer + slicer                      // console.log('page*slicer', page*slicer)
    let decorsKeysPaged = decorsKeys.slice(page*slicer, pageRange);
    
    // +++ Achtung: hier wird ein Array übergeben, der die Information enthält, welche facade und welcher decorIndex aktiv sind
    // = der Button, von dem aus der Dialog aufgerufen wurde
    let facade     = selectDecorActive[1]
    let decorIndex = selectDecorActive[2]
    //console.log('selectDecorActive', selectDecorActive)
    
    let tbMode
    if  (facade == 'top') tbMode = top
    else                  tbMode = bottom
    // console.log('tbMode, decorIndex', tbMode, decorIndex)
    if (decorIndex >= tbMode.decors.length) decorIndex = 0 // werde Dekor 2 entfernt, aber das selectDecorActive bleibt [0,'top', 1]

    let clickedKey
    if (tbMode.decors[decorIndex]) clickedKey = tbMode.decors[decorIndex][0] // Default setzen
    let clickedDecor
    


    /*
    Anmerkung zu useEffect()
    Verwendet nur mit [] führt dazu, dass eder hook nur beim 1. Rendern aufgerufen wird. Dabei passiert etwas unerwartetes: Es werden die Zustände aus dem Store von diesem Moment eingefroren.
    Wenn man dann nach dem Keydown Event "handleKey" aufgerufen wird, wird die Komponente in den Zustand versetzt, als der useEffect() initial aufgerufen wurde.
    (So wird plötzlich aus top bottom, aus decorIndex 0 1, es verschwanden Panels oder tauchten plötzlich wieder auf...)

    Der fix: [tbMode], so wird der Hook immer aktualisiert, wenn sich etwas geändert hat.

    */



    useEffect(() => {  
        document.addEventListener('keydown',                   handleKey)
        return () => document.removeEventListener('keydown',   handleKey)   
    },[tbMode])

    


	const selectDecor = (decor, facade, decorIndex) => {
		// console.log('selectDecor tbMode, decor, facade, decorIndex', tbMode, decor, facade, decorIndex)       
        let newObj = JSON.parse(JSON.stringify(tbMode)) 
        newObj.decors[decorIndex][0] = decor
        if (facade == 'top') setTop(newObj)
        else                 setBottom(newObj)             
        
        setTrigger([facade, true])  
        window.changes++ 
        setSelectDecorActive([0, facade, decorIndex])                    
	}

    

    const decorPage = (val) => {      
        setPage(page + val)
        console.log('page', page)         
	}


    const handleKey = (e) => {    
        if (e.keyCode == 13) {              
            if (document.querySelector('.decor.clicked h3')) clickedKey = document.querySelector('.decor.clicked h3').innerText.toLowerCase()              
            //console.log('handleKey, tbMode, facade, decorIndex', tbMode, facade, decorIndex)
            selectDecor(clickedKey, facade, decorIndex)          
        }
    }




    const toggleClick = (e, key) => {
        if (document.querySelector('.decor.clicked')) document.querySelector('.decor.clicked').classList.remove('clicked') 
        clickedDecor = e.currentTarget
        clickedDecor.classList.add('clicked')
        clickedKey = key
        // console.log('toggleClick clickedKey', clickedKey)
	}





    if (selectDecorActive[0]) {
        return (
        <div className={selectDecorActive[0] !== 0 ? 'select-decor active' : 'select-decor'} onClick={(e) => {if (window.chckClck(e)) setSelectDecorActive([0, facade, decorIndex])}}>
        <div className="inner dialog">
            <div className="closer" onClick={() => setSelectDecorActive([0, facade, decorIndex])} />
            <h2>{decorsKeys.length} {t[lang].decors}</h2>
                <div className="decors scroller">                
                    {decorsKeysPaged.map((key, index) => (
                        <div className={"decor" + (tbMode.decors[decorIndex][0] == key ? ' clicked' : '')} key={index} onClick={(e) => {toggleClick(e, key)}}>
                            <div className={key == false ? 'wrap active' : 'wrap'}>
                            <img draggable="false" src={window.baseUrl + decorObj[key].image_src} />
                            </div>
                            <h3>{key}</h3>
                            <p>{decorObj[key].subtitle}</p>
                        </div>
                    ))}
                </div><br className="clear dist" />
                <div className="footer">
                    <div className="pager">
                        <span>{page*slicer}  - {page*slicer+slicer}</span>
                        {page > 0 ? <button className="previous" onClick={() => {decorPage(-1)}}></button>:''}
                        {pageRange < decorsKeys.length ? <button className="next" onClick={() => {decorPage(1)}}></button> : ''}
                    </div>
                    <button type="button" onClick={() => setSelectDecorActive([0, facade, decorIndex])}>{t[lang].close}</button>
                    <button type="button" onClick={() => selectDecor(clickedKey, facade, decorIndex)} className="default">{t[lang].select}</button>
                </div>
        </div>
    </div>
    )} else {
        return null
    }
}

export default ChooseDecor
