import React from 'react';
import {useEffect} from 'react'
import {useStore} from "../store/useStore";
import t from '../Translation'



function ChooseObject(props) {
    if(window.sq) console.log('render ChooseObject')


    const [config, lang]                                    = useStore(state =>  [state.config, state.lang])
    const [selectObjectActive, setSelectObjectActive]       = useStore((state) => [state.selectObjectActive, state.setSelectObjectActive])
    const [setSideBarActive, setImageSliderActive]          = useStore((state) => [state.setSideBarActive, state.setImageSliderActive])
    const [setConfig, top, setTop, bottom, setBottom]       = useStore((state) => [state.setConfig, state.top, state.setTop, state.bottom, state.setBottom])
    const [setTrigger]                                      = useStore((state) => [state.setTrigger])
    


    useEffect(() => {   
        document.addEventListener('keydown', handleKey)
        return () => document.removeEventListener('keydown', handleKey)
    },[])


    const objectConfigFetch = async (objectName) => {
        window.setSpinner(true)
        //useStore.persist.clearStorage()
        // console.log('useStore.persist',  useStore.persist)
        setImageSliderActive(false) // Compare ausblenden
        const path = '/assets/objects/' + objectName + '/config.json'
        const objectConfig = await (await fetch(path)).json(); 					// console.log('--> useStore', useStore)
        //console.log('objectConfigFetch', objectConfig)          
        setConfig(objectConfig.config) 
        
        // if no configuration, use objectConfig Decors    
        if (!window.configured) {
            setTop(objectConfig.top)
            if (objectConfig.config.conf2) setBottom(objectConfig.bottom) // Current config only if there is a bottom
        }
        window.configurationTmpObj = {'top': [], 'bottom': []}
        window.masks = null
        setSideBarActive(false)
        // console.log('<<<<<<<<<<<<<<<<< top, bottom, config', top, bottom, config)
        
        setTrigger(['both', false])
        window.setSpinner()
    }


    useEffect(() => {	
        //console.log('setTrigger([both, false])')	
		setTrigger(['both', false])
	}, [])

  
    

    const handleKey = (e) => {
        // e.stopPropagation()
        //console.log('chooseObjec handleKey')
        if (e.keyCode == 13) {
            // e.stopPropagation()
            setSelectObjectActive(false)          
        }
    }


    const selectObject = (objectName) => {
        //console.log('ChooseObject objectName', objectName) 
        setSelectObjectActive(false) 
        window.masks = null 
        if (window.gtag) {
            (typeof window.gtag === "function" && window.gtag("event", "facadeconfigurator_select_object", { "facadeconfigurator_object": objectName }))   
        } 
        objectConfigFetch(objectName)   
	}

    
    const objects = [
		{key: 'house', txt: t[lang].house, onClick: ''},
		{key: 'residential', txt: t[lang].residential, onClick: ''},
		{key: 'hotel', txt: t[lang].hotel, onClick: ''},
		{key: 'kindergarden', txt: t[lang].kindergarden, onClick: ''},
		{key: 'industry', txt: t[lang].industryhall, onClick: ''},
		{key: 'balcony', txt: t[lang].balcony, onClick: ''}
	]
    
    
    return (
        <div className={selectObjectActive ? 'select-object active' : 'select-object'} onClick={(e) => {if (window.chckClck(e)) setSelectObjectActive(false)}}>
            <div className="inner">
                <div className="closer" onClick={() => setSelectObjectActive(false)} />
                <h2>{t[lang].chooseobject}</h2>
                <div className="scroller">
                    {objects.map((object) => (
                        <div className="object" key={object.key} onClick={() => selectObject(object.key)}>
                            <div className={object.key == config.object ? 'wrap active' : 'wrap'}>
                                <img draggable="false" src={'assets/objects/' + object.key + '/preview.jpg'} />
                            </div>
                            <h3>{object.txt}</h3>
                        </div>
                    ))}
                </div><br className="clear" />
            </div>
        </div>

    )
}


export default ChooseObject
