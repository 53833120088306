import t from '../Translation'
import {useStore} from "../store/useStore";
import React, {useState} from 'react'





function Login({active, deactivateParent}) {
    const [lang]                            = useStore((state) => [state.lang])

    console.log('window.PFL_AUTHENTICATED', window.PFL_AUTHENTICATED)
       
    const goLogin    = () => {document.location.href = window.PFL_AUTH_URI_LOGIN}
    const goRegister = () => {document.location.href = window.PFL_AUTH_URI_REGISTER}
    

    
    if (active) {
        return (
            <div className="message active" onClick={(e) => {if (window.chckClck(e)) deactivateParent()}}>
                <div className="inner dialog">
                    <p>{t[lang].logininfo}</p>
                    <br />
                    <div className="footer">
                        <button onClick={goLogin}>{t[lang].login}</button>
                        <button onClick={goRegister}>{t[lang].register}</button>
                        <button onClick={deactivateParent}>{t[lang].cancel}</button>
                    </div> 
                </div>
            </div>
        )
    }
}


export default Login
