/*
https://bobbyhadz.com/blog/react-set-default-checked-radio-button

*/
import {useState} from 'react'
import {useStore} from "../store/useStore";
import t from '../Translation'



function SettingsPattern({active, facade}) {


    const lang                               = useStore(state => state.lang)
    const [config, pattern, setPattern]      = useStore((state) => [state.config, state.pattern, state.setPattern])
    const [setTrigger]                       = useStore((state) => [state.setTrigger])

    const onOptionChange = e => {
        //console.log('setPattern e.target.value', e.target.value)
        setPattern(e.target.value)
        window.masks = null
        window.configurationTmpObj = {'top': [], 'bottom': []}
      

        setTrigger(['both', false]) // beide Fassaden aktualisieren
        window.changes++ 
    }

    return (
        <div className={active ? 'settingstab pattern active' : 'settingstab pattern'}>   
             <form>
                {config.patterns.map((pattern, ndx) => (
                    <>
                        {/* <div className={'pattern-' + config.object + '-' + (ndx + 1)} />            */}
                        <img className="pattern-preview" alt="Pattern preview" src={'assets/patterns/pattern_' + config.object + '-' + (ndx + 1) + '.png'} />      
                        <label className="radio"><input type="radio" value={'pattern' + (ndx + 1)} name={"pattern" + facade} onChange={onOptionChange} checked={config.pattern=='pattern'+ (ndx + 1)}/><span>{t[lang]['pattern' + (ndx + 1)]}</span></label> 
                        {/* <label className="radio"><input type="radio" value="2" name={"pattern" + facade} /><span>tmp 1</span></label>         			  */}
                </>
                ))}
                {/* <a href="https://www.pfleiderer.com/dach-de/anwendungen/aussenanwendung/fassaden-bruestungen" className="footerlink">{t[lang]['moreaboutHPL']}</a> */}
            </form>
        </div>	
    )
}

export default SettingsPattern
