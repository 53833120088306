/*
Nicht responsiv:
React Image Comparison Slider - YouTube
https://www.youtube.com/watch?v=6SZHre00pqg
https://www.npmjs.com/package/react-image-comparison-slider (
import ImageSlider from "react-image-comparison-slider"
<ImageSlider 
    image1="/assets/tmp_compare1.jpg"
    image2="/assets/tmp_compare2.jpg"
    //onSlide={() => {}}
    sliderColor = "white"
    handleColor = "white"
    handleBackgroundColor = "transparent"
    // sliderInitialPosition = {0.5}
    // customHandle = {imageSliderHandle}            
/> 



Neu: 
https://github.com/nerdyman/react-compare-slider


*/
import {useEffect, useRef, useState, Suspense} from 'react';
import {useStore} from "../store/useStore";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import t from '../Translation'
import Spinner from "./Spinner";
import Login from "./Login";


function Compare(props) {
    if(window.sq) console.log('render Compare')
    //console.log('x render Compare')

    const [lang, config]                            = useStore((state) => [state.lang, state.config])                 
    const [imageSliderActive, setImageSliderActive] = useStore((state) => [state.imageSliderActive, state.setImageSliderActive])   
    const [canvasDataUrl, setCanvasDataUrl]         = useState()
    const [compareProjects, setCompareProjects]     = useState([]) 
    const [activeCompare, setActiveCompare]         = useState(-1) 
    
    const comparebox = useRef()



    useEffect(() => {
        if (imageSliderActive) {
            if (window.PFL_AUTHENTICATED) getProjectListForCompare()
                    
            // console.log('/////////////////////////////////////////////////////////////// useEffect Compare canvas', props.canvas )
            let url = props.canvas.current.toDataURL() //+ '?tmp=' + Math.random()
            setCanvasDataUrl(url)
            window.setTimeout(function() {
                let url = props.canvas.current.toDataURL()
                setCanvasDataUrl(url)
            }, 300);
        }


    }, [imageSliderActive]);



    const closeSideBar = () => {
        setImageSliderActive(false)
    }
    


    const getProjectListForCompare = () => {
        console.log('---- Listing Projects');
		console.log('Sending GET request to backend', window.baseUrl + window.FACADE_API_LIST_PROJECTS);

		fetch(window.baseUrl + window.FACADE_API_LIST_PROJECTS, )
			.then((res) => {
                //console.log('res', res); 
                // Prüfen noch authorized? (Fuer den Fall, dass man sich bei Pfleiderer abmeldet, die App nicht neu lädt...)
                if (res.status == 401) {
                    console.log('401'); 
                    window.setSpinner()
                    // setSaveProjectActive(false)
                    window.PFL_AUTHENTICATED = false 
                    //setSaveProjectActive(true)
                }           
                return res.json()})
			.then((data) => {
			    // console.log('Fetched projects', data)
               setCompareProjects(data)


			})
    }


    const loadHresImage = (src) => {      
        console.log('loadHresImage', src)                
        let img = document.querySelector('.image-slider div img')
        window.setSpinner(true)
        img.src = window.baseUrl + src
        img.onload = function() {window.setSpinner()}
    }
    
    const toggleCompare = () => {
        const cB = comparebox.current
        cB.classList.contains('close') ? cB.classList.remove('close'): cB.classList.add('close')
    }


    const deactivateParent = () => {
        console.log('deactivateParent')
        setImageSliderActive(false)
    }


    let counter = 0
    const count = () => {
        counter++
        //console.log('counter', counter);
    }

   



    if (imageSliderActive) {     
        
        if (!window.PFL_AUTHENTICATED) {
            return <Login active={true} deactivateParent={deactivateParent}/>
        }

        return (
        <div className={imageSliderActive ? 'image-slider active' : 'image-slider'}>                       
            <ReactCompareSlider
                boundsPadding={0}
                //itemOne={<ReactCompareSliderImage alt="Image one" src={url}/>}
                itemOne={<ReactCompareSliderImage alt="Image one" src={canvasDataUrl} />}
                itemTwo={<ReactCompareSliderImage alt="Image two" src={canvasDataUrl} />}
                position={50}
                style={{
                    height: '100%',
                    width: '100%'
                }}
            />           
            <div className="compare-box" ref={comparebox}>
                <div className="closer" onClick={closeSideBar} />
                <h2 onClick={toggleCompare}>{t[lang].compare}</h2>   
                <div className="scroller">
                    <Suspense fallback={Spinner}>
                    {compareProjects.map((project, ndx) => (
                        <>
                        {JSON.parse(project.configuration).config.object == config.object ?
                            <div id={project.id} test={count()} key={ndx} className={activeCompare==ndx ? 'preview active' : 'preview'} onClick={() => {setActiveCompare(ndx); loadHresImage(project.image)}}>
                                <h3>{project.name}</h3>
                                <img src={window.baseUrl + project.thumbnail}/>
                                <div className="marker" />
                            </div>
                        : ''}
                        </>
                    ))}
                   
                    {counter == 0 ? <div className="comparinfo">{t[lang].compareinfo}</div> :  ''}
                    </Suspense>
                </div>
            </div> 
        </div>                                   
        )
    }
}


export default Compare
