
/*

https://mui.com/material-ui/react-app-bar/#app-bar-with-responsive-menu


npm install @mui/material @emotion/react @emotion/styled
npm install @mui/icons-material



package.json hinzufügen:

"homepage": "https://www.o8design.de/fassade",
"homepage": "https://www.o8design.de/fassade_slider/",
"homepage": "https://pfl3d.dev.salient.de/",










*/

import React from 'react';
import ReactDOM from 'react-dom/client';
import "./index.css";
// import Loader from "./Components/Loader";
import reportWebVitals from "./reportWebVitals";
	
import App from "./App";
//const App = React.lazy(() => import("./App"));







const root = ReactDOM.createRoot(document.getElementById('facadegenerator'));
root.render(
  	// <React.StrictMode>
	// <Suspense fallback={<Loader/>}>
		<App />		
	// </Suspense>	
  	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();