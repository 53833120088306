import React, {useEffect, useState, useRef} from 'react'
import {useStore}       from "../store/useStore";
import SettingsPattern  from './SettingsPattern';
import SettingsDecor    from './SettingsDecor';
import SettingsMounting from './SettingsMounting';
import t from '../Translation'



function FacadeSettingsTop({active, backButton, tabsArr}) {
    if (window.sq) console.log("render FacadeSettingsTop")
    
    const lang                        = useStore(state => state.lang)
    const [tabs, setTabs]             = useState([0,0,1]) // Defaultwert, wird durch useEffect runterschschaltet auf [0,1,0] Dekore activ
    const [footerlink, setFooterlink] = useStore((state) => [state.footerlink, state.setFooterlink])  
    const [config]                    = useStore((state) => [state.config])   
    const didMount = useRef(false);

    
    useEffect(() => {
        //console.log('useEffect Top')
        if      (tabs[1])    toggleTabs([1,0,0])
        else if (tabs[2])    toggleTabs([0,1,0])        
	}, [tabsArr])



    useEffect(() => {
        if (active == 'top') {
            //console.log('useEffect Top Every')
            if (tabs[0])  backButton.current.style.display = 'none'
            else          backButton.current.style.display = 'inline-block'  
        }
	})


  
    const toggleTabs = (arr) => {
        setTabs(arr)
        if (arr[0])  backButton.current.style.display = 'none'
        else         backButton.current.style.display = 'inline-block'
	}



    return (
        <div className={active == 'top' ? 'settings top active' : 'settings top'}>
            <h2>{t[lang][config.conf1[2]]}</h2>
            <ul className="tabs">
                <li className={tabs[0] ? 'active' : ''} onClick={() => {toggleTabs([1,0,0]); setFooterlink(1)}}>{t[lang].pattern}</li>
                <li className={tabs[1] ? 'active' : ''} onClick={() => {toggleTabs([0,1,0]); setFooterlink(0)}}>{t[lang].decor}</li>
                <li className={tabs[2] ? 'active' : ''} onClick={() => {toggleTabs([0,0,1]); setFooterlink(1)}}>{t[lang].mounting}</li>
            </ul>
            <br className="clear" />
            <SettingsPattern    active={tabs[0]} facade="top" />
            <SettingsDecor      active={tabs[1]} facade="top" />
            <SettingsMounting   active={tabs[2]} facade="top" /> 
        </div>       
    )
}

export default FacadeSettingsTop
