/*




*/
import {useEffect} from 'react';
import {useStore} from "../store/useStore";
import SliderConditional from './SliderConditional';
import SliderStyle from './SliderStyle';
import t from '../Translation'




function SettingsDecor({active, facade}) {
    if (window.sq) console.log("render SettingsDecor")


    const decorObj = window.FACADE_CONFIGURATOR.decors

    let useDecors = []
    const [lang]                                    = useStore((state) => [state.lang])
    const [top, setTop, bottom, setBottom]          = useStore((state) => [state.top, state.setTop, state.bottom, state.setBottom])
    const [setSelectDecorActive]                    = useStore((state) => [state.setSelectDecorActive])
    const [setTrigger]                              = useStore((state) => [state.setTrigger])
    const [decorTrigger]                            = useStore((state) => [state.decorTrigger, state.setDecorTrigger])
    const styleDefaultValue                          = useStore((state) => [state[facade].style]) 

   
    if (facade == 'top')            useDecors = top.decors
    else if (facade == 'bottom')    useDecors = bottom.decors



    useEffect(() => {
       // console.log('decorTrigger', decorTrigger)
       if (decorTrigger[0] > 0) decorAdd(decorTrigger[1]) // top or bottom
    }, [decorTrigger]);



    const decorAdd = (facade) => {
        window.configured = true
        console.log('/////////////////////////////// decorAdd', facade)
        let newObj
        if (facade == 'top') {  
            if (top.decors.length > 2) return // Sicherung maximal 3       
            newObj = JSON.parse(JSON.stringify(top))
            // newObj.decors.push(["u12188", 0]) 
            if (top.decors.length == 1)   newObj.decors.push(["u12188", 0]) // u18505 | u12188
            else                          newObj.decors.push(["u18029", 0])       
            if (top.decors.length==1)   {newObj.decors[0][1] *= .5; newObj.decors[1][1] = 50}
            else                        {newObj.decors[0][1] *= .666666; newObj.decors[1][1] *= .666666; newObj.decors[2][1] = 33.33333}
            setTop(newObj)
            setTrigger([facade, false])
        } else {
            if (bottom.decors.length > 2) return // Sicherung maximal 3   
            newObj = JSON.parse(JSON.stringify(bottom))
            // newObj.decors.push(["u12188", 0])           
            if (bottom.decors.length == 1)  newObj.decors.push(["u12188", 0]) 
            else                            newObj.decors.push(["u18029", 0])
            if (bottom.decors.length==1)    {newObj.decors[0][1] *= .5; newObj.decors[1][1] = 50}
            else                            {newObj.decors[0][1] *= .666666; newObj.decors[1][1] *= .666666; newObj.decors[2][1] = 33.33333}
            setBottom(newObj)
            setTrigger([facade, false])
        }
        window.changes++
    }

    const decorRemove = (facade, decorIndex) => {
        console.log('/////////////////////////////// decorRemove', facade)
        window.configured = true
        let newObj, lastSPos
        if (facade == 'top') {
            newObj = JSON.parse(JSON.stringify(top))
            newObj.decors.splice(decorIndex, 1)
            if (top.decors.length==2)   {newObj.decors[0][1] = 100}
            else                        {lastSPos = top.decors[2][1]/2; newObj.decors[0][1] += lastSPos; newObj.decors[1][1] += lastSPos} // letzter Slider fällt weg, dann vermitteln
            setTop(newObj)
            setTrigger(['top', false])
        } else {
            newObj = JSON.parse(JSON.stringify(bottom))
            newObj.decors.splice(decorIndex, 1)
            if (bottom.decors.length==2) {newObj.decors[0][1] = 100}
            else                         {lastSPos = bottom.decors[2][1]/2; newObj.decors[0][1] += lastSPos; newObj.decors[1][1] += lastSPos} // letzter Slider fällt weg, dann vermitteln
            setBottom(newObj)
            setTrigger(['bottom', false])
        }
        window.changes++
    }


    const setRotation = (facade, decorIndex, rotation) => {
        window.configured = true
        let newObj                                                            //console.log('setRotation', facade, decorIndex, rotation)        
        if (facade == 'top') {
            newObj = JSON.parse(JSON.stringify(top))
            newObj.decors[decorIndex][2] = rotation
            setTop(newObj)
            setTrigger(['top', true]) // true = noRandom
        } else {
            newObj = JSON.parse(JSON.stringify(bottom))
            newObj.decors[decorIndex][2] = rotation
            setBottom(newObj)
            setTrigger(['bottom', true])  // true = noRandom
        } 
        window.changes++       
    }

    //console.log('++ useDecors, facade,', useDecors, facade)
        // test={console.log('item, decorIndex', item, decorIndex)} 




    return (
        <div className={active ? 'settingstab decor active' : 'settingstab decor'}> 

        {useDecors.map((item, decorIndex) => (        
            <div key={decorIndex} className={"panel panel" + (decorIndex+1)}>
                {decorIndex > 0 ? <div className="closer" onClick={() => decorRemove(facade, decorIndex)} /> : ''}
                <h3>{t[lang].decor} {decorIndex+1}</h3>
                <div className="decor">
                    <div className={"tile " + useDecors[decorIndex][0]}><img src={window.baseUrl + decorObj[useDecors[decorIndex][0]].image_src} alt={useDecors[decorIndex][0]}/></div>
                    <div className="articlenum">{item[0]}</div>
                    <div className="title">{decorObj[useDecors[decorIndex][0]].subtitle}</div>
                    <br className="clear" />
                </div>
                <div className="decor-edit-group">
                <button className="decor-change" onClick={() => setSelectDecorActive([1, facade, decorIndex])}>{t[lang].decorchange}</button>                
                    {item[0].indexOf('u') == -1 ?                    
                        <><input id={"check" + facade + decorIndex} className="checkbox" type="checkbox" checked={useDecors[decorIndex][2]} name="rotate" value="1" onChange={(e) => setRotation(facade, decorIndex, e.target.checked)}></input>
                        <label htmlFor={"check" + facade + decorIndex} className="forcheck decor-rotate">{t[lang].decorrotate}</label></> : ''
                    }
                </div>
                <div className={useDecors.length == 1 ? "slidergroup disabled" : "slidergroup"}>
                    <h3 className="hslider">{t[lang].distribution}</h3>
                    <label className="l1">100</label>
                    <label className="l2">0</label>
                    <SliderConditional size="small" defaultValue={item[1]} decorIndex={decorIndex} facade={facade} />  
                </div>              
            </div>         
            ))
        }
        {useDecors.length < 3 ? <button className="decor-add" onClick={() => decorAdd(facade)}>{t[lang].decoradd}</button> : ''}
        {useDecors.length > 1 ? <SliderStyle size="small" defaultValue={styleDefaultValue} facade={facade} /> : ''}
        </div>	
    )
}



export default SettingsDecor
