/*
Zustand. React state management set up in a few minutes | TSH.io
https://tsh.io/blog/zustand-react/
https://docs.pmnd.rs/zustand/guides/updating-state


Persist:
https://docs.pmnd.rs/zustand/integrations/persisting-store-data


*/
import {create} from 'zustand'
import {persist} from 'zustand/middleware'


window.baseConfig = {
    top: { 
        decors:              [["u12044", 100, 0]],
        style: 0
    },
    
    bottom: {
        decors:              [["r20038", 100, 1]],
        style: 0     
    },       
    config: {
        "patterns":     ["pattern1", "pattern2", "pattern3"],
        "pattern":      "pattern1",
        "conf1":        [30,30, "facadetop"],
        "conf2":        [60,60, "facadebottom"],
        "object":       "house",   
        "mounting":     "m1",    
        "panel":        "duropal-xterior-compact-single-sided-lacquering",
        "name":         "",
        "id":           ""    
    },  
    "lang" : window.language   
} 




export const useStore = create(
    persist(
        (set, get) => ({
        version: window.version,
        setVersion: (val) => set((state) => ({version: val})),

        top:        window.baseConfig.top,
        bottom:     window.baseConfig.bottom,
        config:     window.baseConfig.config,
        lang:       window.baseConfig.lang,
        /*
        top: { 
            //decors:            [["r20038", 100, 0], ["r20038", 0, 0]],
            decors:              [["u16051", 100, 0]],
            style: 0
        },
        bottom: {
            //decors:             [["r20038", 100, 0], ["r20038", 0, 0],  ["r20038", 0, 0]],
            decors:             [["s63028", 100, 0]],
            style: 0     
        },        
        config: {
            "conf1":        [40,30],
            "conf2":        [50,60],
            "object":       "house",   
            "mounting":     "m1",    
            "panel":        "duropal-xterior-compact-double-sided-lacquering",
        },


        */
        
        setLang: (lang) => set((state) => ({
            lang: lang
        })),
       
        setMounting: (val) => set((state) => ({
            config: {
                ...state.config,
                mounting: val
            }
        })),
        setPattern: (val) => set((state) => ({
            config: {
                ...state.config,
                pattern: val
            }
        })),


        sideBarActive: false,
        setSideBarActive: (val)     => {set(  (state) => ({sideBarActive: val})  )},

        imageSliderActive: false,
        setImageSliderActive: (val) => {set(  (state) => ({imageSliderActive: val})  )},
       
        selectDecorActive: [0,'',0],
        setSelectDecorActive: (arr) => {set(  (state) => ({selectDecorActive: arr})  )},

        saveProjectActive: false,
        setSaveProjectActive: (val) => {set(  (state) => ({saveProjectActive: val})  )},
        
        productListActive: false,
        setProductListActive: (val) => {set(  (state) => ({productListActive: val})  )},        

        helpLayerActive: false,
        setHelpLayerActive: (val) => {set(  (state) => ({helpLayerActive: val})  )}, 

        inShoppingCartActive: false,
        setInShoppingCartActive: (val) => {set(  (state) => ({inShoppingCartActive: val})  )}, 





        
        fullScreen:     () => {window.toggleFullScreen()},
        //openContact:    () => {window.open(window.location.hostname + window.FACADE_CONFIGURATOR.contactUrl, '_blank'); if (window.gtag) {(typeof window.gtag === "function" && window.gtag("event", "facadeconfigurator_contact", {  }))}},
        //goShoppingCart: () => {window.open(window.location.hostname + window.page_cart,'_blank')},
        openContact:    () => {
            console.log('window.FACADE_CONFIGURATOR.contactEmail', window.FACADE_CONFIGURATOR.contactEmail)
            if (window.FACADE_CONFIGURATOR.contactEmail && window.FACADE_CONFIGURATOR.contactEmail != undefined ) document.location.href = window.FACADE_CONFIGURATOR.contactEmail
            else window.open(window.FACADE_CONFIGURATOR.contactUrl, '_blank'); 
            
            //window.FACADE_CONFIGURATOR = {	...	'contactUrl': "\/dach-en\/solutions\/exterior-use\/facades-parapets\/contact-form-facade-configurator",	'contactEmail': "service@meier.de". // Oder null}
            if (window.gtag) {(typeof window.gtag === "function" && window.gtag("event", "facadeconfigurator_contact", {  }))}
        },
        goShoppingCart: () => {window.open(window.page_cart,'_blank')},

        selectObjectActive: false,
        setSelectObjectActive: (val) => { /*alert(val);*/ set(  (state) => ({selectObjectActive: val})  )},
        
        windowSize: [window.innerWidth, window.innerHeight],
        setWindowSize: (arr) => {set(  () => ({windowSize: arr})  )}, // console.log('setWindowSize', arr); 

        setStyleTop: (val) => set((state) => ({
            top: {
                ...state.top,
                style: val
            }
        })),

        setStyleBottom: (val) => set((state) => ({
            bottom: {
                ...state.bottom,
                style: val
            }
        })),

        setName: (val) => set((state) => ({
            config: {
                ...state.config,
                name: val
            }
        })),
        setId: (val) => set((state) => ({
            config: {
                ...state.config,
                id: val
            }
        })),
        
        
        setTop:    (obj) => set((state) => ({ top: obj})),
        setBottom: (obj) => set((state) => ({bottom: obj})),
        setConfig: (obj) => set((state) => ({config: obj})), 

        trigger: [],
        setTrigger: (arr) => {set(  (state) => ({trigger: arr})  )},

        decorTrigger: [0, ''],
        setDecorTrigger: (arr) => {set((state) => ({decorTrigger: arr}))},

        footerlink: false,
        setFooterlink: (val) => {set(  (state) => ({footerlink: val})  )},
       

    }),
    {name: 'facades'}
))




