import Slider from '@mui/material/Slider';
import {useStore} from "../store/useStore";



function SliderConditional({facade, defaultValue, decorIndex}) {
    if (window.sq) console.log("render SliderConditional")
    const [top, setTop]       = useStore((state) => [state.top, state.setTop])
    const [bottom, setBottom] = useStore((state) => [state.bottom, state.setBottom])
    const [setTrigger] = useStore((state) => [state.setTrigger])



    let isDisabled = false
    if      (facade == 'top'    && top.decors.length    == 1) isDisabled = true
    else if (facade == 'bottom' && bottom.decors.length == 1) isDisabled = true
    // console.log("render SliderConditional facade decorIndex", facade, decorIndex)
    // console.log('+ window.activeSlider', window.activeSlider)       





    function getValue(mode, decorIndex, facade, e, value) {
        
        //console.log('getValue top', top, decorIndex, facade, value)
        let tbMode, restToShare 
        if (facade == 'top')    tbMode = top
        else                    tbMode = bottom    
        // Slider berechnen
        const q =  window.q
        tbMode.decors[decorIndex][1] = value
       
 
        // ________________________________________________ 
        // 2 Slider  
        if (tbMode.decors.length == 2) {
            if (decorIndex == 0)    tbMode.decors[1][1] = 100 - value
            else                    tbMode.decors[0][1] = 100 - value




        // ________________________________________________ 
        // 3 Slider  
        } else if (tbMode.decors.length == 3) {
            
            // ________________________________________________        
            // Slider 1
            if (decorIndex == 0) {
                restToShare = parseFloat(100 - value)
                //console.log('decorIndex', decorIndex)
                //console.log('window.activeSlider', window.activeSlider)
                //console.log('value', value)
                if (window.activeSlider === null || window.activeSlider != decorIndex) {                   
                    q[1] = tbMode.decors[1][1]/restToShare
                    q[2] = tbMode.decors[2][1]/restToShare
                    if (restToShare == 0)  {q[1] = 0.5; q[2] = 0.5} // wenn der obere Slider 100/ hat, dann ist restToShare = 0 -> geteilt /0 = nan
                    window.activeSlider = decorIndex  
                    //console.log('set restToShare,  q[1],  q[2]', restToShare, q[1], q[2])
                    //console.log('-----------------> set window.activeSlider, decorIndex, value', window.activeSlider, decorIndex, value)             
                }
                //console.log('-> window.activeSlider, decorIndex, value, restToShare, q[1], q[2]', window.activeSlider, decorIndex, value, restToShare, q[1], q[2] )  
                tbMode.decors[1][1] = q[1] * restToShare
                tbMode.decors[2][1] = q[2] * restToShare
            }

            // ________________________________________________  
            // Slider 1            
            else if (decorIndex == 1) {
                restToShare = parseFloat(100 - value)
                if (window.activeSlider === null || window.activeSlider != decorIndex) {                   
                    q[0] = tbMode.decors[0][1]/restToShare
                    q[2] = tbMode.decors[2][1]/restToShare
                    if (restToShare == 0)  {q[0] = 0.5; q[2] = 0.5}
                    window.activeSlider = decorIndex   
                }
                tbMode.decors[0][1] = q[0] * restToShare
                tbMode.decors[2][1] = q[2] * restToShare
            }

            // ________________________________________________  
            // Slider 2
            else  if (decorIndex == 2) {
                restToShare = parseFloat(100 - value)
                if (window.activeSlider === null || window.activeSlider != decorIndex) {                   
                    q[0] = tbMode.decors[0][1]/restToShare
                    q[1] = tbMode.decors[1][1]/restToShare
                    if (restToShare == 0)  {q[0] = 0.5; q[1] = 0.5}
                    window.activeSlider = decorIndex         
                }
                tbMode.decors[0][1] = q[0] * restToShare
                tbMode.decors[1][1] = q[1] * restToShare
            }
        }
        
      
        let newObj = JSON.parse(JSON.stringify(tbMode))
        newObj.decors[decorIndex][1] = value
        //console.log('newObj', newObj)

        if (facade == 'top') setTop(newObj)
        else                 setBottom(newObj)

        if (mode) {
            setTrigger([facade, false])
            window.changes++
        }
        
        
          
    }
 
    const numFormatter = (n) => {return parseInt(n)}
    
    return (
       <Slider 
            size                = "xsmall" 
            defaultValue        = {defaultValue}  
            disabled            = {isDisabled}
            value               = {facade=='top' ? top.decors[decorIndex][1] : bottom.decors[decorIndex][1]} 
            //aria-label          = "Small" 
            valueLabelDisplay   = "auto"             
            valueLabelFormat    = {value => <div>{numFormatter(value)}</div>}
            onChange            = {(e, value) => getValue(false, decorIndex, facade, e, value)} 
            onChangeCommitted   = {(e, value) => getValue(true,  decorIndex, facade, e, value)} 
       />        
    )
}



export default SliderConditional
