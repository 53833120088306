import { Countertops } from '@mui/icons-material';
import React, {useEffect} from 'react';
import {useStore} from "../store/useStore";
import t from '../Translation'
import InShoppingCart from './InShoppingCart';


function ProductList() {
    if (window.sq) console.log("render ProductList")

    const [productListActive, setProductListActive]         = useStore((state) => [state.productListActive, state.setProductListActive])
    const [top, setTop, bottom, setBottom]                  = useStore((state) => [state.top, state.setTop, state.bottom, state.setBottom, state.config])  
    const [config, lang]                                   = useStore((state) => [state.config, state.lang]) 
    const [inShoppingCartActive, setInShoppingCartActive]  = useStore((state) => [state.inShoppingCartActive, state.setInShoppingCartActive]) 
    const decorObj = window.FACADE_CONFIGURATOR.decors
    //console.log('decorObj', decorObj)
    
    const samples = {
        "R20351": "sample_decoboard#162995",
        "U12115": "sample_decoboard#162996",
        "F76006": "sample_decoboard#161683",
        "F76037": "sample_decoboard#161684",
        "R50084": "sample_decoboard#161695",
        "R55004": "sample_decoboard#161696",
        "R55072": "sample_decoboard#161697",
        "S60036": "sample_decoboard#161698",
        "S63028": "sample_decoboard#161699",
        "S64011": "sample_decoboard#161700",
        "U11102": "sample_decoboard#161701",
        "U12000": "sample_decoboard#161702",
        "U12044": "sample_decoboard#161703",
        "U12188": "sample_decoboard#161704",
        "U12215": "sample_decoboard#161705",
        "U16051": "sample_decoboard#161707",
        "U16058": "sample_decoboard#161708",
        "U18004": "sample_decoboard#161709",
        "U18029": "sample_decoboard#161710",
        "U18074": "sample_decoboard#161711",
        "U18501": "sample_decoboard#161712",
        "U18505": "sample_decoboard#161713",
        "U19007": "sample_decoboard#161714",
        "U19015": "sample_decoboard#161715",
        "F76115": "sample_decoboard#161685",
        "F76143": "sample_decoboard#161686",
        "F76146": "sample_decoboard#161687",
        "F76149": "sample_decoboard#161688",
        "R20027": "sample_decoboard#161689",
        "R20038": "sample_decoboard#161690",
        "R20095": "sample_decoboard#161691",
        "R20320": "sample_decoboard#161692",
        "R34015": "sample_decoboard#161693",
        "R38000": "sample_decoboard#161694"
    }


    const structeLink           = window.FACADE_CONFIGURATOR.structure.em.detail_link
    const productLink           = window.FACADE_CONFIGURATOR.panels[config.panel].detail_link
    // const ausschreibungsLink    = 'pfleiderer/Pfleiderer_Kompaktplatten_Ausschreibungstexte.zip'

    const ausschreibungsLink    = '/fileadmin/system/public/facade-generator/app/pfleiderer/Pfleiderer_Kompaktplatten_Ausschreibungstexte.zip'
    //console.log('window.location.host', window.location.host)
    //console.log('window.location.hostname', window.location.hostname)
    //const ausschreibungsLink    = '//' + window.location.host + '/pfleiderer/Pfleiderer_Kompaktplatten_Ausschreibungstexte.zip' // host ist mit Port, hostname ohne
    //const musterLink            = '/dach-de/produkte/dekorative-platten/detail/duropal-xterior-compact-f-single-sided-lacquering#samples'

    let allDecors = [...top.decors]
    if (bottom && bottom.decors) allDecors.push(...bottom.decors)
    
    // doppelte rausnehmen
    let tmpObj = {}
    for(let i =0; i < allDecors.length; i++) {
        tmpObj[allDecors[i][0]] = 1
    }

    allDecors = Object.keys(tmpObj)                         // console.log('allDecors', allDecors)
    const lacqueringArr = t[lang][config.panel].split(' – ') // String wird in 2 Teile gesplittet, für h2 und p
   
   






    useEffect(() => {   
        document.addEventListener('keydown', handleKey)
        return () => document.removeEventListener('keydown', handleKey)
    },[])




    const orderSamples = (decor, subtitle) => {
        const sample = samples[decor.toUpperCase()]        
        const shoppingcartCounter = document.querySelector('.shoppingcart-counter')
        const inShoppingcart = document.querySelector('.in-shoppingcart')
        console.log('shoppingcartCounter', shoppingcartCounter)
        
        inShoppingcart.style.transitionDuration = '0.3s'

        setInShoppingCartActive(true)
        window.setTimeout(function() {inShoppingcart.style.transitionDuration = '2s'; setInShoppingCartActive(false)}, 2000) // ausfaden


        if (window.shopUtilities) {
            window.shopUtilities.add('cart', sample, {
            id: sample,
            title: 'Werkstoffmuster',
            subtitle: subtitle,
            subsubtitle: decor.toUpperCase() + ' EM'// U16051
            })      


            window.shopUtilities.changed(() => {
                // Anzahl der Items im Warenkorb auslesen - darauf basierend setzen wir auf der Webseite ein Marker mit der Anzahl am Icon.
                const count = window.shopUtilities.getItemCount('cart')
                shoppingcartCounter.innerText = count
                shoppingcartCounter.classList.remove('hidden')

            }, true, 'cart');
        }

        //shoppingcartCounter.classList.remove('hidden')
        //document.querySelector('.shoppingcart-counter').innerText = parseInt(Math.random() * 20)
    }


    const handleKey = (e) => {
        //console.log(e.keyCode)
        //e.stopPropagation()
        if (e.keyCode == 13) {
            setProductListActive(false) 
        }
    }

    return (
        <>
            <InShoppingCart active={inShoppingCartActive} setActive={setInShoppingCartActive} lang={lang}/>
            <div className={productListActive ? 'product-list active' : 'product-list'} onClick={(e) => {if (window.chckClck(e)) setProductListActive(false)}}>
                <div className="inner dialog">
                    <h2>{t[lang].myproductlist}</h2>
                    {config.name ? <h3>{config.name}</h3> : ''}
                    <div className="scroller"> 
                        
                        <table><tbody>   
                            <tr>
                                <th>{t[lang].decor}</th>  
                                <th>{t[lang].structure}</th>                                
                                <th>{t[lang].product}</th>    
                                <th>{t[lang].format}</th>    
                                <th>{t[lang].mounting}</th>    
                            </tr>
                            {allDecors.map((decor, index) => (
                                <tr key={index}>                                
                                    <td className="decor">                                
                                        <a target="_blank" href={window.baseUrl + decorObj[decor].detail_link}>
                                            <img draggable="false" src={window.baseUrl + decorObj[decor].image_src} />                        
                                            <span>
                                                <h3>{decor}</h3>
                                                <p>{decorObj[decor].subtitle}</p>
                                            </span>
                                        </a>
                                        <a target="_blank" onClick={() => orderSamples(decor, decorObj[decor].subtitle)} className="textlink order-samples">{t[lang].ordersamples}</a>
                                    </td>
                                    <td className="decor structure" style={{minWidth: '160px'}}>
                                        <a target="_blank" href={window.baseUrl + structeLink}>
                                            <img draggable="false" src="assets/productlist-EM-structure.jpg" />
                                            <span>
                                                <h3>EM</h3>
                                                <p>Exterior Matt</p>
                                            </span>
                                        </a>   
                                    </td> 
                                    <td className="decor product">
                                        <a target="_blank" href={window.baseUrl + productLink}>
                                            <img draggable="false" src="assets/productlist-duropal-exterior.jpg" />
                                            <span>
                                                <h3>{lacqueringArr[0]}</h3>                                        
                                                <p>{lacqueringArr[1]}</p>
                                            </span>
                                        </a>                                     
                                    </td>
                                    <td className="decor format"> 
                                        <h3>2800 x 2070</h3>
                                    </td>
                                    <td className="decor mounting"> 
                                        <h3>{t[lang][config.mounting]}</h3>
                                    </td>
                                    
                                </tr>
                            ))}
                        </tbody></table>  
                    </div>
                    <div className="footer"> 
                        <p className="producthint">{t[lang].producthint}</p>
                        {lang == 'de' && <a className="textlink" target="_blank" onClick={() => {if (window.gtag) {(typeof window.gtag === "function" && window.gtag("event", "facadeconfigurator_download_ausschreibung", {  }))}   }} href={ausschreibungsLink}>{t[lang].expulsion}</a>}                      
                        <button className="right" type="submit" onClick={() => setProductListActive(false)}>{t[lang].close}</button>
                    </div>
                
                </div>
            </div>
        </>
    ) 
}

export default ProductList
