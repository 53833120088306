import FacadeSettingsTop        from './FacadeSettingsTop';
import FacadeSettingsBottom     from './FacadeSettingsBottom';
import {useStore} from "../store/useStore";
import {useRef, useState } from 'react'
import t from '../Translation'


function Sidebar() {
    if (window.sq) console.log("render Sidebar")
    //console.log("render Sidebar")

    const lang                              = useStore(state => state.lang)
    const [sideBarActive, setSideBarActive] = useStore((state) => [state.sideBarActive, state.setSideBarActive])
    const [tabsArrTop, setTabsArrTop]       = useState([0,1,0])
    const [tabsArrBottom, setTabsArrBottom] = useState([0,1,0])
    const [decorTrigger, setDecorTrigger]   = useStore((state) => [state.decorTrigger, state.setDecorTrigger])
    const [top, bottom]                     = useStore((state) => [state.top, state.bottom])
    // checken, ob andere Fenster darüber liegen, dann nicth schließen mit Enter
    //const [selectDecorActive, productListActive, saveProjectActive, selectObjectActive]= useStore((state) => [state.selectDecorActive, state.productListActive, state.saveProjectActive, state.selectObjectActive])

    const backButton = useRef()
   
    let showAddButton = true
    if (sideBarActive == 'top'      && top.decors.length > 2)       showAddButton = false
    if (sideBarActive == 'bottom'   && bottom.decors.length > 2)    showAddButton = false
    const [footerlink] = useStore((state) => [state.footerlink])  
    let footerLinkVal = window.FACADE_CONFIGURATOR.facadesUrl

    const closeSideBar = () => {setSideBarActive(false)}

    const triggerGoBack = () => {
        console.log('triggerGoBack, sideBarActive', sideBarActive)
        const arr=[0,0,0]
        if (sideBarActive == 'top')    setTabsArrTop([...arr]);
        if (sideBarActive == 'bottom') setTabsArrBottom([...arr]);
	}

    const triggerAddDecor = () => {
        setDecorTrigger([decorTrigger[0]+1, sideBarActive])
	}




    return (
    <div className={sideBarActive ? 'sidebar active' : 'sidebar'}>   
        <div className="inner">
            <div className="closer" onClick={closeSideBar} />
            <FacadeSettingsTop    active={sideBarActive} tabsArr={tabsArrTop}    backButton={backButton}/>  
            <FacadeSettingsBottom active={sideBarActive} tabsArr={tabsArrBottom} backButton={backButton}/>
        </div>
        <div className="footer">
            {footerLinkVal && <a href={footerLinkVal} target="_blank" className="footerlink">{t[lang]['moreaboutHPL']}</a>}
            {showAddButton ? <button className="decor-add" onClick={triggerAddDecor}>{t[lang].decoradd}</button> : ''}
            <button id="sidebarBack" ref={backButton} onClick={triggerGoBack}>{t[lang].back}</button>
            <button className="default" onClick={() => {setSideBarActive('', false)}}>{t[lang].ready}</button>  
        </div>			  			
    </div>	
    )
}

export default Sidebar
