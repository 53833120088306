import Slider from '@mui/material/Slider';
import {useStore} from "../store/useStore";
import t from '../Translation'





function SliderStyle({facade, defaultValue}) { 
    if (window.sq) console.log("render SliderStyle")

    const lang                            = useStore(state => state.lang)
    const [styleTop, setStyleTop]         = useStore((state) => [state.styleTop, state.setStyleTop])
    const [styleBottom, setStyleBottom]   = useStore((state) => [state.styleBottom, state.setStyleBottom])
    const [setTrigger]                    = useStore((state) => [state.setTrigger])

   // let defaultValue = useStore((state) => [state[facade].style]) 


    
    function getValue(mode, facade, e, styleValue) {     
        if (mode) setTrigger([facade, false])                                   //console.log('getValue', styleValue) 
        if (facade =='top') setStyleTop(styleValue) 
        else                setStyleBottom(styleValue) 
        window.changes++
    }
 

    const numFormatter = (n) => {return parseInt(n)}

    return (
        <div className="panel transparent slider-style">
            <h3>{t[lang].style}</h3>
            <label className="l1">{t[lang].ordered}</label>
            <label className="l2">{t[lang].mixed}</label>            
            <Slider 
                size                = "xsmall" 
                //defaultValue        = {defaultValue}   
                //aria-label          = "Small" 
                value               = {facade == 'top' ? styleTop : styleBottom} 
                valueLabelDisplay   = "auto" 
                valueLabelFormat    = {value => <div>{numFormatter(value)}</div>}
                onChange            = {(e, value) => getValue(false, facade, e, value)} 
                onChangeCommitted   = {(e, value) => getValue(true,  facade, e, value)} 
            />           
       </div>       
    )
}

export default SliderStyle
