import {useStore} from "../store/useStore";
import t from '../Translation'






function SettingsMounting({active}) {

    const [config, setMounting] = useStore((state) => [state.config, state.setMounting])
    const lang                  = useStore(state => state.lang)
    //console.log('render SettingsMounting config.mounting', config.mounting)

    const onOptionChange = e => {
        //console.log('setMounting e.target.value', e.target.value)
        setMounting(e.target.value)
    }

    // t[lang].rivets
    // t[lang].screws
    // t[lang].invisiblehandles

    
    return (    
        <div className={active ? 'settingstab mounting active' : 'settingstab mounting'}>   
            <form>
                <div className="wrap">
                    <div className="drawing m1" /><div className="productimage m1" />           
                    <label className="radio"><input type="radio" value="m1" name="mounting" onChange={onOptionChange} checked={config.mounting=='m1'} /><span>{t[lang].m1}</span></label>
                </div>
                <div className="wrap">
                    <div className="drawing m2" /><div className="productimage m2" /> 
                    <label className="radio"><input type="radio" value="m2" name="mounting" onChange={onOptionChange} checked={config.mounting=='m2'} /><span>{t[lang].m2}</span></label>
                </div> 
                <div className="wrap">
                    <div className="drawing m3" /><div className="productimage m3" /> 
                    <label className="radio"><input type="radio" value="m3" name="mounting" onChange={onOptionChange} checked={config.mounting=='m3'} /><span>{t[lang].m3}</span></label> 
                </div>
                <div className="wrap">
                    <div className="drawing m4" /><div className="productimage m4" /> 
                    <label className="radio"><input type="radio" value="m4" name="mounting" onChange={onOptionChange} checked={config.mounting=='m4'} /><span>{t[lang].m4}</span></label> 
                </div>
                {/* <a href="https://www.pfleiderer.com/dach-de/anwendungen/aussenanwendung/fassaden-bruestungen" className="footerlink">{t[lang]['moreaboutHPL']}<br></br>&nbsp;</a> */}
            </form>
            
        </div>   
        	
    )
}

export default SettingsMounting
