import React from 'react'
import t from '../Translation'
import {useStore} from "../store/useStore";



function InShoppingCart ({active, setActive, lang}) { 

    const [goShoppingCart]        = useStore((state) => [state.goShoppingCart])

    //if (active) {   
        return (
            <div className={active ? 'in-shoppingcart active' : 'in-shoppingcart'} onClick={(e) => {if (window.chckClck(e)) setActive(false)}}>
                <div className="inner">
                    <div className="closer" onClick={() => setActive(false)} />
                    <h3>{t[lang].shoppingcartinfo}</h3>
                <button className="goto-shoppingcart default" onClick={goShoppingCart}>{t[lang].gotoshoppingcart}</button>
                </div>
            </div>

        )
    //}
}

export default InShoppingCart
