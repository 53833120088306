import {useStore} from "../store/useStore";
import t from '../Translation'





function FacadeButtons() {
    if(window.sq) console.log('render FacedeButtons')

    const [config, lang]        = useStore((state) => [state.config, state.lang])
	const [setSideBarActive]    = useStore((state) => [ state.setSideBarActive])
    
    return (
        <>
            <div className="conf-wrap" style={{ left: config.conf1[0] + '%', top: config.conf1[1] + '%' }} onClick={() => setSideBarActive('top')}>
                <div className="conf conf1" />
                <div className="slide"><span>{t[lang][config.conf1[2]]}</span></div>
            </div>
            {config.conf2 ? <><div className="conf-wrap" style={{ left: config.conf2[0] + '%', top: config.conf2[1] + '%' }} onClick={() => setSideBarActive('bottom')}>
                <div className="conf conf2" />
                <div className="slide"><span>{t[lang][config.conf2[2]]}</span></div>
            </div></> : ''}
        </>
    )
}



export default FacadeButtons
